import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import './css/Contact.css';

function ContactForm() {
  const [contactForm, setContactForm] = useState({
    name: '',
    lastname: '',
    email: '',
    subject: '',
    message: '',
  });
  const [sending, setSending] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleContactFormChange = (e) => {
    setContactForm({ ...contactForm, [e.target.id]: e.target.value });
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token); // Save the token from reCAPTCHA
  };

  const handleContactFormSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      alert('Please complete the reCAPTCHA.');
      return;
    }

    setSending(true);

    try {
      const data = {
        ...contactForm,
        token: recaptchaToken, // Include the reCAPTCHA token
      };

      const response = await axios.post('https://wttw.ngo/send_mail.php', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('Email sent successfully!', response.data);
      setEmailSent(true);
      setSending(false);

      // Reset form and reCAPTCHA
      setContactForm({
        name: '',
        lastname: '',
        email: '',
        subject: '',
        message: '',
      });
      setRecaptchaToken(null);
    } catch (error) {
      console.error('Failed to send email. Error:', error);
      setSending(false);
      alert('Failed to send email. Please try again later.');
    }
  };

  const closePopup = () => {
    setEmailSent(false);
  };

  useEffect(() => {
    if (emailSent) {
      const timer = setTimeout(() => {
        setEmailSent(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [emailSent]);

  return (
    <div className="contact-form-container">
      {emailSent && (
        <div className="success-popup">
          <div className="success-popup-content">
            <i
              className="bi bi-check-circle-fill text-success"
              style={{ fontSize: '4rem' }}
            ></i>
            <h2>Message Sent!</h2>
            <p>Your message has been sent successfully.</p>
            <button className="btn btn-primary" onClick={closePopup}>
              Close
            </button>
          </div>
        </div>
      )}

      <form className="contact-form" onSubmit={handleContactFormSubmit}>
        <h1>Contact Us</h1>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Your Name"
            required
            value={contactForm.name}
            onChange={handleContactFormChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="lastname" className="form-label">
            Last Name
          </label>
          <input
            type="text"
            className="form-control"
            id="lastname"
            placeholder="Your Lastname"
            required
            value={contactForm.lastname}
            onChange={handleContactFormChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="name@example.com"
            required
            value={contactForm.email}
            onChange={handleContactFormChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="subject" className="form-label">
            Subject
          </label>
          <input
            type="text"
            className="form-control"
            id="subject"
            placeholder="Subject"
            required
            value={contactForm.subject}
            onChange={handleContactFormChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="message" className="form-label">
            Message
          </label>
          <textarea
            className="form-control"
            id="message"
            rows="3"
            required
            value={contactForm.message}
            onChange={handleContactFormChange}
          ></textarea>
        </div>

        <ReCAPTCHA
          sitekey="6LfvF_wjAAAAAEhlzIzsWAIX-FlN1RZpn1VMRDh2"
          onChange={handleRecaptchaChange}
        />

        <button
          type="submit"
          className="btn btn-primary"
          disabled={sending || !recaptchaToken}
          style={{ marginTop: '15px' }}
        >
          {sending ? 'Sending...' : 'Send Message'}
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
