import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    const userConsent = Cookies.get("userConsent");
    if (!userConsent) {
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("userConsent", "accepted", { expires: 365 });
    // Enable cookies or tracking
    console.log("Cookies accepted");
    setShowConsent(false);
  };

  const handleDeny = () => {
    Cookies.set("userConsent", "denied", { expires: 365 });
    // Disable cookies or tracking
    console.log("Cookies denied");
    setShowConsent(false);
  };

  if (!showConsent) {
    return null;
  }

  return (
    <div style={styles.consentBox}>
      <p style={styles.text}>
        We use cookies to improve your experience. You can accept or deny their usage.
      </p>
      <button onClick={handleAccept} style={styles.acceptButton}>
        Accept
      </button>
      <button onClick={handleDeny} style={styles.denyButton}>
        Deny
      </button>
    </div>
  );
};

const styles = {
  consentBox: {
    position: "fixed",
    bottom: "10px",
    left: "10px",
    right: "10px",
    padding: "10px",
    backgroundColor: "#333",
    color: "#fff",
    borderRadius: "5px",
    zIndex: 1000,
  },
  text: {
    marginBottom: "10px",
  },
  acceptButton: {
    marginRight: "10px",
    backgroundColor: "green",
    color: "white",
    border: "none",
    padding: "5px 10px",
    cursor: "pointer",
  },
  denyButton: {
    backgroundColor: "red",
    color: "white",
    border: "none",
    padding: "5px 10px",
    cursor: "pointer",
  },
};

export default CookieConsent;
