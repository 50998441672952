import React, { useState, Suspense } from 'react';
import teamstyles from './css/teamstyles.module.css';
import logo from './img/logo3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench, faUserTie, faMagnifyingGlass,faSeedling, faVideo, faPeopleGroup,faUserGraduate, faBroadcastTower, faCogs, faHeartPulse, faHammer, faSection, faScaleBalanced, faComments } from '@fortawesome/free-solid-svg-icons';

const DepartmentComponents = {
  HResources: React.lazy(() => import('./departments/HResources')),
  Production: React.lazy(() => import('./departments/Production')),
  Communication: React.lazy(() => import('./departments/Communication')),
  Academic: React.lazy(() => import('./departments/Academic')),
  Finances: React.lazy(() => import('./departments/Finances')),
  Legal: React.lazy(() => import('./departments/Legal')),
  Education: React.lazy(() => import('./departments/Education')),
  Health: React.lazy(() => import('./departments/Health')),
  Operation: React.lazy(() => import('./departments/Operation')),
  Environment: React.lazy(() => import('./departments/Environment')),
  Administration: React.lazy(() => import('./departments/Administration')),
  Marketing: React.lazy(() => import('./departments/Marketing')),
};

const Team = ({ onBack }) => {
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [showTransition, setShowTransition] = useState(false);
  const [showDepartments, setShowDepartments] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  const handleToggleTeam = () => {
    if (!showTransition) {
      setShowTransition(true);
      setTimeout(() => {
        setShowDepartments(true);
          setFadeIn(true);
      }, 0); // Duration of the white screen animation
    } else {
      setFadeIn(false);
      setTimeout(() => {
        setShowDepartments(false);
        setShowTransition(false);
      }, 1000); // Delay to wait for fade-out to complete
    }
  };

  const handleSelectDepartment = (department) => {
    document.body.style.height = `3000px`; 
    setSelectedDepartment(department);

  }

  
  const handleBackToTeam = () => {
    setSelectedDepartment(null);
    document.body.style.height = ''; // Reset body height when unmounll', handleScroll);
  };

  const icons = {
    Administration: faUserTie,
    Production: faVideo,
    Communication: faComments,
    HResources: faPeopleGroup,
    Education: faUserGraduate,
    Academic: faMagnifyingGlass,
    Finances: faScaleBalanced,
    Legal: faSection,
    Health: faHeartPulse,
    Environment: faSeedling,
    Operation: faWrench,
    Marketing: faBroadcastTower
  };

  const DepartmentComponent = DepartmentComponents[selectedDepartment];

  return (
    <div className={teamstyles["team-container"]}>
      <img className={`${teamstyles.logo} ${selectedDepartment ? teamstyles.fixed : ''}`} src={logo} alt="Logo" />
      {!selectedDepartment ? (
        <>
          <div className={`${teamstyles["section-content"]} ${fadeIn ? teamstyles["hidden"] : teamstyles["visible"]}`}>
            <h1>About Our Team</h1>
            <p>We are defenders, documentarians, and artists from different countries </p><p>who share the same vision: to break down the barriers that divide humanity."</p>
          </div>
          {showTransition && (
            <div className={`${teamstyles["transition-screen"]} ${fadeIn ? teamstyles["expand"] : teamstyles["shrink"]}`}></div>
          )}
          {showDepartments && (
            <div className={`${teamstyles["departments-container"]} ${fadeIn ? teamstyles["fade-in"] : ""}`}>
              {Object.keys(icons).map((label, index) => (
                <button
                  key={label}
                  className={teamstyles["department-button"]}
                  style={{ animationDelay: `${index * 0.3 + 1}s` }}
                  onClick={() => handleSelectDepartment(label)}
                >
                  <FontAwesomeIcon className={teamstyles.icon} icon={icons[label]} size="3x" />
                  <p>{label}</p>
                </button>
              ))}
            </div>
          )}
          <button
            className="btn btn-primary"
            onClick={handleToggleTeam}
            style={{ position: 'fixed', bottom: '10px', left: '10px', zIndex: 100 }}
          >
            {showDepartments ? 'Back' : 'See our Team'}
          </button>
        </>
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          {DepartmentComponent && <DepartmentComponent onBack={handleBackToTeam} />}
        </Suspense>
      )}
      <button
        className="btn btn-primary"
        onClick={onBack}
        style={{ position: 'fixed', bottom: '10px', right: '10px', zIndex: 100 }}
      >
        Home
      </button>
    </div>
  );
};

export default Team;
