import React, { useState, useEffect, lazy, Suspense, useRef } from 'react';
import axios from 'axios';
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import camera from './img/camera.png'
import logo from './img/logo3.png';
import logosmall from './img/foreground.png'
import Unity from './img/Unity.jpg';
import Humanity from './img/Humanity.jpg';
import Community from './img/Community.jpg';
import Sustainability from './img/Sustainability.jpg';
import Peace from './img/Peace.jpg';
import Respect from './img/Respect.jpg';
import Unity2 from './img/Unity2.jpg';
import Humanity2 from './img/Humanity2.jpg';
import Community2 from './img/Community2.jpg';
import Sustainability2 from './img/Sustainability.jpg';
import Peace2 from './img/Peace.jpg';
import Respect2 from './img/Respect.jpg';
import worldMap from './img/world.png';
import Team from './Team';
import SimpleParallax from 'simple-parallax-js';
import bridgeImage from './img/bridge.png';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { GoogleReCaptchaProvider,useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ContactForm from './Contact';
import CookieConsent from "./CookieConsent";


const HaitiComponent = lazy(() => import('./projects/Haiti'));
const MexicoComponent = lazy(() => import('./projects/Mexico'));

const countryComponents = {
  'Dominican Republic': HaitiComponent,
  Mexico: MexicoComponent,
};

const programmes = [
  {
    title: 'Unity',
    text: 'We trust in the communion of human being with the natural world',
    text2: 'Creating a bond and honoring her as our home',
    image: require('./img/unity.png'),
  },
  {
    title: 'Humanity',
    text: 'We hold dearly all who weave the great web of the human family',
    text2: 'We born free, with equal right, dignity & respect.',
    image: require('./img/humanity.png'),
  },
  {
    title: 'Community',
    text: 'We seek to tend others in a selfless and unconditional manner',
    text2:
      'Meeting where everyone is with full recognition of intrinsic dignity for each form of existence',
    image: require('./img/comm.png'),
  },
  {
    title: 'Sustainability',
    text: 'We seek a healthy balance of economic development',
    text2:
      'Social well-being and environmental care, resisting the concept of limited growth',
    image: require('./img/sustain.png'),
  },
  {
    title: 'Peace',
    text: 'We exude harmony and understand in our relationships',
    text2:
      'Advocating for a peaceful middle ground as a resolution for conflicts',
    image: require('./img/peace.png'),
  },
  {
    title: 'Respect',
    text: 'We see others as we see ourselves',
    text2:
      'We treat others with respect and kindness, regardless of nationality, religion, status, gender, orientation, age, or any other factor.',
    image: require('./img/respect.png'),
  },
];

function App() {
  const images = [logo,Unity,Unity2, Humanity,Humanity2, Community,Community2, Sustainability, Peace, Respect,worldMap,bridgeImage];
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [backgroundScale, setBackgroundScale] = useState(1);
  const [foregroundScale, setForegroundScale] = useState(1);
  const [backgroundTranslate, setBackgroundTranslate] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const [isFixed, setIsFixed] = useState(true);
  const [isFixedFirst, setIsFixedFirst] = useState(true);
  const [isFixedSecond, setIsFixedSecond] = useState(false);
  const [isFixedThird, setIsFixedThird] = useState(false);
  const [isFixedFourth, setIsFixedFourth] = useState(false);
  const fourthContentRef = useRef(null);
  const scrollingRef = useRef(false);
  const logoBackgrounds = [Unity, Humanity, Community, Sustainability, Peace, Respect];
  const logoBackgrounds2 = [Unity2, Humanity2, Community2, Sustainability2, Peace2, Respect2];
  const [currentBackgroundArray, setCurrentBackgroundArray] = useState(logoBackgrounds); 
  const [currentProgram, setCurrentProgram] = useState(0);
  const [firstContentVisible, setFirstContentVisible] = useState(false);
  const [secondContentVisible, setSecondContentVisible] = useState(false);
  const [fadeInActive, setFadeInActive] = useState(false);
  const [thirdContentVisible, setThirdContentVisible] = useState(false);
  const [fourthContentVisible, setFourthContentVisible] = useState(false);
  const [fifthContentVisible, setFifthContentVisible] = useState(false);
  const [showProjects, setShowProjects] = useState(false);
  const [showTeam, setShowTeam] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [showCountryComponent, setShowCountryComponent] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const [progressValues, setProgressValues] = useState({
    helpedPeople: 0,
    helpedDocuments: 0,
    builtSchools: 0,
    servedFood: 0,
  });
  const scrollY = window.scrollY;
  const windowHeight = window.innerHeight;
  const fadeOutThreshold = windowHeight; // End of the parallax section
  const firstContentThreshold = fadeOutThreshold + 750;
  const secondContentThreshold = firstContentThreshold + 240 * programmes.length;
  const thirdContentThreshold = secondContentThreshold + 1700;
  const fourthContentThreshold = thirdContentThreshold + 1200;
  const fifthContentThreshold = fourthContentThreshold + 100;
  const fourthContentTop = fourthContentRef.current?.offsetTop || 0;
  const scrollwithinFourthContent = scrollY - fourthContentTop;

  const worldMapRef = useRef(null);
  const packageRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);
  const [packageStates, setPackageStates] = useState([
    { visible: true, expanded: true }, // First package
    { visible: false, expanded: false }, // Second package
    { visible: false, expanded: false }, // Third package
  ]);
  const [showDonatePopup, setShowDonatePopup] = useState(false); // New state for popup
  const [donationAmount, setDonationAmount] = useState(''); // New state for amount
  const [donationMessage, setDonationMessage] = useState(''); // New state for message
  const [contactForm, setContactForm] = useState({
    name: '',
    lastname: '',
    email: '',
    subject: '',
    message: '',
  });
  const [sending, setSending] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Moved packageThresholds outside of useEffect to access them in handlePackageClick
  const packageThresholds = {
    package1End: 5000,
    package2End: 5400,
    package3End: 5800,
    fourthContentEnd: 6200,
  };
  const scrollToMainContent = () => {
    window.scrollTo(0,800, {
      duration: 2000,
      delay: 0,
      smooth: 'easeInOutQuart',
    });

};
useEffect(() => {
  let loadedCount = 0;

  images.forEach((src) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      loadedCount += 1;
      setLoadingProgress(Math.round((loadedCount / images.length) * 100));
      if (loadedCount === images.length) {
        setTimeout(() => setIsLoaded(true), 1000); // Add delay for smooth transition
      }
    };
  });
}, []);

  useEffect(() => {

    const handleScroll = () => {


      setBackgroundScale(1 + (scrollY * 0.0005) / 2);
      setForegroundScale(1 + scrollY * 0.0005 * 2);
      setBackgroundTranslate(scrollY * 0.1);
      setOpacity(scrollY < fadeOutThreshold ? 1 : 0);
      setIsFixed(scrollY < fadeOutThreshold);
      setIsFixedFirst(scrollY < firstContentThreshold);
      setIsFixedSecond(scrollY < secondContentThreshold - 300);
      const worldMapTop = worldMapRef.current?.getBoundingClientRect().top;

      if (worldMapTop <= 0) {
        setTimeout(() => {
          setProgressValues({
            helpedPeople: (9000 / 20000) * 100,
            helpedDocuments: (15000 / 100000) * 100,
            builtSchools: (20 / 100) * 100,
            servedFood: (24500 / 100000) * 100,
          });
        }, 200);
      } else if (
        scrollY - 1200 < thirdContentThreshold ||
        scrollY > fourthContentThreshold
      ) {
        setProgressValues({
          helpedPeople: 0,
          helpedDocuments: 0,
          builtSchools: 0,
          servedFood: 0,
        });
      }

      // Adjusted logic for packageStates and piggy bank visibility
      const newPackageStates = [...packageStates];

      if (scrollwithinFourthContent < packageThresholds.package1End) {
        // First package is expanded
        newPackageStates[0] = { visible: true, expanded: true };
        newPackageStates[1] = { visible: false, expanded: false };
        newPackageStates[2] = { visible: false, expanded: false };
      } else if (scrollwithinFourthContent < packageThresholds.package2End) {
        // First package collapses, second package expands
        newPackageStates[0] = { visible: true, expanded: false };
        newPackageStates[1] = { visible: true, expanded: true };
        newPackageStates[2] = { visible: false, expanded: false };
      } else if (scrollwithinFourthContent < packageThresholds.package3End) {
        // Second package collapses, third package expands
        newPackageStates[0] = { visible: true, expanded: false };
        newPackageStates[1] = { visible: true, expanded: false };
        newPackageStates[2] = { visible: true, expanded: true };
      } else if (
        scrollwithinFourthContent >= packageThresholds.piggyBankStart &&
        scrollwithinFourthContent < packageThresholds.fourthContentEnd
      ) {
        newPackageStates[0] = { visible: true, expanded: false };
        newPackageStates[1] = { visible: true, expanded: false };
        newPackageStates[2] = { visible: true, expanded: true };
      } 

      setPackageStates(newPackageStates);

      if (scrollY > fadeOutThreshold && scrollY < firstContentThreshold) {
        if (!firstContentVisible) {
          setFirstContentVisible(true);
          setFadeInActive(true);
        }
      } else {
        setFirstContentVisible(false);
        setFadeInActive(false);
      }

      if (scrollY >= firstContentThreshold && scrollY < thirdContentThreshold) {
        if (!secondContentVisible) {
          setSecondContentVisible(true);
          setFadeInActive(true);
        }
      } else {
        setSecondContentVisible(false);
        setFadeInActive(false);
      }

      if (scrollY >= secondContentThreshold  - 300 && scrollY < thirdContentThreshold) {
        if (!thirdContentVisible) {
          setIsFixedSecond(false);
          setThirdContentVisible(true);
          setFadeInActive(true);
        }
      } else {
        setThirdContentVisible(false);
        setFadeInActive(false);
      }
    
      if (scrollY >= thirdContentThreshold && scrollY < fourthContentThreshold) {
        if (!fourthContentVisible) {
          setFourthContentVisible(true);
          setFadeInActive(true);
        }
      } else {
        setFourthContentVisible(false);
        setFadeInActive(false);
      }
    

      if (scrollY >= fifthContentThreshold - 100|| scrollY > 6240) {
        if (!fifthContentVisible) {
          setFifthContentVisible(true);
          setFadeInActive(true);
        }
      } else {
        setFifthContentVisible(false);
        setFadeInActive(false);
      }

      setCurrentProgram(
        Math.min(
          Math.floor((scrollY - firstContentThreshold) / 200),
          programmes.length - 1
        )
      );
      if (window.scrollY > 0 && fourthContentVisible === true) {
        setIsFixedFourth(true);
      } else {
        setIsFixedFourth(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [
    firstContentVisible,
    secondContentVisible,
    thirdContentVisible,
    fourthContentVisible,
    fifthContentVisible,
    packageStates,
    isFixed,
  ]);

  useEffect(() => {
    // Function to check the screen width and set the background array accordingly
    const updateBackgroundArray = () => {
      if (window.innerWidth > 1150) {
        setCurrentBackgroundArray(logoBackgrounds2);
      } else {
        setCurrentBackgroundArray(logoBackgrounds);
      }
    };

    // Initial check
    updateBackgroundArray();

    // Add an event listener to handle window resize
    window.addEventListener('resize', updateBackgroundArray);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', updateBackgroundArray);
  }, []);

  const handleCountryChange = (event) => {
    const newCountry = event.target.value;
    window.location.href = `https://projects.wttw.ngo/${newCountry}`;
  };

  const handlePackageClick = (index) => {

    // Calculate the target scroll position for the clicked package
    let targetScrollY;
    if (index === 0) {
      // Scroll to the start of the third content
      targetScrollY = fourthContentThreshold;
    } else if (index === 1) {
      // Scroll to where the second package expands
      targetScrollY =
        fourthContentTop + packageThresholds.package1End + 1; // Adjusted value
    } else if (index === 2) {
      // Scroll to where the third package expands
      targetScrollY =
      fourthContentTop + packageThresholds.package2End + 1; // Adjusted value
    }

    // Scroll to the target position smoothly
    window.scrollTo({
      top: targetScrollY,
      behavior: 'smooth',
    });
  };

  const handleBackToProjects = () => {
    setShowCountryComponent(false);
    setSelectedCountry('');
    scroll.scrollTo(2400, { smooth: true });
  };

  const handleBackToMainContent = () => {
    setShowTeam(false);
    scroll.scrollTo(1000, { smooth: true });
  };

  const handleDonateSubmit = (details, data) => {
    // Close the popup after donation
    setShowDonatePopup(false);
    // Reset the form fields
    setDonationAmount('');
    setDonationMessage('');
  };

  const handleContactFormChange = (e) => {
    setContactForm({ ...contactForm, [e.target.id]: e.target.value });
  };


  const handleContactFormSubmit = async (e) => {
    e.preventDefault();

    setSending(true);

    try {
      const token = await executeRecaptcha('contact_form');

      const data = {
        name: contactForm.name,
        lastname: contactForm.lastname,
        email: contactForm.email,
        subject: contactForm.subject,
        message: contactForm.message,
        token: token, // Include the reCAPTCHA token
      };

      const response = await axios.post('https://wttw.ngo/send_mail.php', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setEmailSent(true);
      setSending(false);
      // Reset form
      setContactForm({
        name: '',
        lastname: '',
        email: '',
        subject: '',
        message: '',
      });
    } catch (error) {
      console.error('Failed to send email. Error:', error);
      setSending(false);
      alert('Failed to send email. Please try again later.');
    }
  };

  if (!isLoaded) {
    return (
      <div className="loading-screen">
        <div className="circle-wrapper">
          <img src={camera} alt="Logo" className="loading-logo" />
          <div className="loading-circle"></div>
        </div>
        <p className="loading-text">{loadingProgress}%</p>
      </div>
    );
  }
  return (
    <PayPalScriptProvider
    options={{ 'client-id': 'AePRfwr-g3csNWUB1kr4LkGpLLe9008YAj-_ymqmGhJ7F7C68TEXkX3Oxgp_F2b19JKpFy_SSwTtfGyd', currency: 'USD' }} // Replace with your PayPal Client ID
  >
    <div className="App">
    {/* <CookieConsent /> */}
      {showTeam ? (
        <Team onBack={handleBackToMainContent} />
      ) : showCountryComponent ? (
        <Suspense fallback={<div>Loading...</div>}>
          {React.createElement(countryComponents[selectedCountry], {
            onBack: handleBackToProjects,
          })}
        </Suspense>
      ) : (
        <>
          <div className={`parallax-container ${isFixed ? 'sticky' : 'relative'}`} style={{ opacity }}>
            <div style={{ transform: `translateY(${backgroundTranslate}px) scale(${backgroundScale})`,}} className="background-layer"/>
            <div style={{ transform: `scale(${foregroundScale})` }} className="foreground-layer"/>
          <div className="arrow-container">
            <div className="circle-animation" onClick={scrollToMainContent}>
              <i className="bi bi-arrow-down"></i>
            </div>
          </div>
          </div>
          <div style={{ height: '900vh' }}></div>
          {firstContentVisible && (
            <div
              className={`first-content ${fadeInActive ? '' : 'fadeIn'} ${
                isFixedFirst ? 'fixed' : 'relative'
              }`}
              style={{
                top: isFixedFirst ? '0' : '-600vh',
                padding: '50px 20px',
                zIndex: '10',
              }}
            >
              <img src={logo} alt="Logo" className="logo" />
              <h2 className="title">Unity Beyond Borders</h2>
              <div className="title-content">
              <p><a style={{color:"#024080",fontWeight:"bold"}}> WTTW</a> is a non-governmental organization committed to breaking down the barriers that divide humanity by documenting them and showcasing humanitarian efforts.</p>
              </div>
              <button className="btn btn-primary moreabout" onClick={() => setShowTeam(true)}>More about us & the Team</button>
              </div>
              )}
            {secondContentVisible && (
              <div
              className={`second-content ${fadeInActive ? '' : 'fadeIn'} ${
                isFixedSecond ? 'fixed' : 'relative'
              }`}
              style={{
                top: isFixedSecond ? '0' : '-645vh',
                padding: '0px 0px',
                height: '100vh',
                zIndex: '10',
              }}
            >
            <div className="logo-container">
               {/* <img className="logo" src={logo} alt="Logo" /> */}
               <div className="oval"></div>
              <div className="logobackground-layer" key={currentProgram} style={{ backgroundImage: `url(${currentBackgroundArray[currentProgram]})` }}/>
              <div className="logoforeground-layer" /> 
            </div>
              <div className="aboutus">
              <h2 className="title-values">What we stand for</h2>
                <div className="programmes">
                  {programmes.map((programme, index) => (
                    <div
                      key={index}
                      className={`programme-item ${
                        index === currentProgram ? 'visible' : ''
                      }`}
                    >
                      <div className={"programme-content"}
                      >
                        <img
                          src={programme.image}
                          alt={programme.title}
                          className="programme-image"
                        />
                        <h5
                          className={`programme-title programme-title-${index}`}
                        >
                          <a className='programme-titletext'>
                            {programme.title}
                          </a>
                        </h5>
                      </div>
                      <div className="programme-text">
                      <p className="programme-text-up">{programme.text}</p>
                      <p>{programme.text2}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <button className="btn btn-primary donatenow" onClick={() => setShowDonatePopup(true)} >Donate Now</button>
              </div>
            </div>
          )}
          {thirdContentVisible && (
            <div
              className={`third-content ${fadeInActive ? '' : 'fadeIn'} ${
                isFixedThird ? 'sticky' : 'relative'
              }`}
              style={{ top: isFixedThird ? '-65vh' : '-650vh', zIndex: '5' }}
            >
              <SimpleParallax>
                <img src={bridgeImage} alt="Bridge" className="bridge-image" />
              </SimpleParallax>
              <h2 className="projects-title">
                Building Bridges for Humanity
              </h2>
              <div className="sticky">
                <img
                  ref={worldMapRef}
                  src={worldMap}
                  alt="World Map"
                  className="world-map sticky"
                />
                <div>
                  <div className="project-filter">
                    <select
                      className="form-select"
                      onChange={handleCountryChange}
                    >
                      <option value="">Choose a Country</option>
                      <option value="haiti">Haiti</option>
                    </select>
                  </div>
                  <h2 className="milestone-title"style={{ marginTop: '5%' }}>Our current Milestones</h2>
                  <div className="progress-bars" style={{ marginTop: '5%' }}>
                    <div className="progress-bar-container">
                      <p>Helped People</p>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{
                            width: `${progressValues.helpedPeople}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="progress-bar-container">
                      <p>Helped with Governmental Documents</p>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{
                            width: `${progressValues.helpedDocuments}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="progress-bar-container">
                      <p>Built Schools</p>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{
                            width: `${progressValues.builtSchools}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="progress-bar-container">
                      <p>Served Food Bags</p>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{
                            width: `${progressValues.servedFood}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {fourthContentVisible && (
            <div
              className={`fourth-content ${fadeInActive ? '' : 'fadeIn'}`}
              ref={fourthContentRef}
              style={{
                position: isFixedFourth ? 'fixed' : 'relative',
                top: '0',
                width: '100%',
                zIndex: 5,
                padding: '20px',
              }}
            >
              <h4>Be a part of the Journey</h4>
              <div className="packages-container">
                {[
                  {
                    id: 1,
                    image: require('./img/package1.jpg'),
                    text: "I'm a Waller",
                    details: [
                      'Access to exclusive content & tutorials on human rights.',
                      'All previous tier benefits, plus:',
                      'Human rights documentaries (<5 minutes)',
                      'Tutorials & lessons',
                      'Commissions (delivered by mail)',
                      'Behind-the-scenes content',
                    ],
                    price: '$5/Moth',
                  },
                  {
                    id: 2,
                    image: require('./img/package2.jpg'),
                    text: 'Unstoppable Waller',
                    details: [
                      'Includes access to advanced tools for growth.',
                      'All previous tier benefits, plus:',
                      'Human rights documentaries (<5 minutes)',
                      'Tutorials & lessons',
                      'Commissions (delivered by mail)',
                      'Behind-the-scenes content',
                    ],
                    price: '$14.99/Month',
                  },
                  {
                    id: 3,
                    image: require('./img/package3.jpg'),
                    text: 'Philanthropist Waller',
                    details: [
                      'Access to exclusive content, tutorials & legal advice on human rights.',
                      'All previous tier benefits, plus:',
                      '100% of the subscription is dedicated to communities & environment',
                      'Legal advice on International Human Rights Law & Environmental Law',
                      'Weekly publications (pdf)',
                      'Q&A',
                    ],
                    price: '$24.99/Month',
                  },
                ].map((tab, index) => {
                  const state = packageStates[index];
                  if (!state.visible) return null;
                  return (
                    <div
                      key={tab.id}
                      ref={packageRefs.current[index]}
                      className={`packagetab ${
                        state.expanded ? 'visible' : 'collapsed'
                      }`}
                      onClick={() => handlePackageClick(index)}
                      style={{
                        // Apply margin-top: -10% to uncollapsed packages starting from the second one
                        marginTop:
                          index > 0 && state.expanded ? '-10%' : '0',
                      }}
                    >
                      <img
                        src={tab.image}
                        alt={`Package ${tab.id}`}
                        className="packagetab-image"
                      />
                      <p className="packagetab-text">{tab.text}</p>
                      {state.expanded && (
                        <ul className="packagetab-details">
                          {tab.details.map((detail, idx) => (
                            <li key={idx}>{detail}</li>
                          ))}
                        </ul>
                        
                      )}
                        <div className="package-footer">
                        <span className="package-price">{tab.price}</span>
                        <button
                          className="btn btn-primary more-button"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering package onClick
                            window.location.href =
                              'https://www.patreon.com/WeThroughTheWall';
                          }}
                        >
                          More
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
        
            </div>
          )}
          {fifthContentVisible && (
            <div
              className={`fifth-content ${fadeInActive ? '' : 'fadeIn'}`}
              style={{
                position: 'fixed',
                top: '5vh',
                padding: '50px 20px',
                width: '100%',
              }}
            >
              <ContactForm />
            </div>
          )}
        </>
      )}
              {showDonatePopup && (
          <div className="donate-popup">
            <div className="donate-popup-content">
              <h2>Make a Donation</h2>
              <button
                className="close-button"
                onClick={() => setShowDonatePopup(false)}
              >
                &times;
              </button>
              <div className="donation-form">
                <div className="form-group">
                  <label htmlFor="donationAmount">Amount (USD):</label>
                  <input
                    type="number"
                    id="donationAmount"
                    className="form-control"
                    value={donationAmount}
                    onChange={(e) => setDonationAmount(e.target.value)}
                    min="1"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="donationMessage">Message:</label>
                  <textarea
                    id="donationMessage"
                    className="form-control"
                    value={donationMessage}
                    onChange={(e) => setDonationMessage(e.target.value)}
                  ></textarea>
                </div>
                {/* PayPal Buttons */}
                <PayPalButtons
                  style={{ layout: 'vertical' }}
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            value: donationAmount || '1', // Default to 1 if no amount
                          },
                          description: donationMessage,
                        },
                      ],
                    });
                  }}
                  onApprove={(data, actions) => {
                    return actions.order
                      .capture()
                      .then((details) => {
                        handleDonateSubmit(details, data);
                      })
                      .catch((error) => {
                        console.error('Error capturing order:', error);
                      });
                  }}
                  onError={(err) => {
                    console.error('PayPal Checkout onError', err);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </PayPalScriptProvider>
  );
}

export default App;
